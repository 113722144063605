import * as React from "react"
import SiteContainer from "../../components/site-container";
import { Link } from "gatsby";
import PricingSection from "../../components/PricingSection";
import { Helmet } from "react-helmet";

export function Pricing() {
  return (
    <div>
      <Link to="/pricing/">Pricing</Link>
    </div>
  )
}

export function SignupPage() {
  return (
    <div>
      <Link to="/signup/">Signup</Link>
    </div>
  )
}


const PricingPage = () => {
  return (
    <SiteContainer>
      <Helmet>
        <title>Firstparty Pricing - 1M Events for Free</title>
        <meta name="description" content="Firstparty Collects 10 Million Events per Month for Free. Unlimited data sources, warehouses, audiences, segments, and users; no credit card required." />
        
      </Helmet>
      <PricingSection />
    </SiteContainer>
  )
}

export default PricingPage